import { render, staticRenderFns } from "./index.vue?vue&type=template&id=015f2aee&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=015f2aee&prod&scoped=true&lang=css&"
import style1 from "./index.vue?vue&type=style&index=1&id=015f2aee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "015f2aee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomepageHeroBlackFriday: require('/opt/build/repo/components/Homepage/HeroBlackFriday.vue').default,HomepageHeroNew: require('/opt/build/repo/components/Homepage/HeroNew.vue').default,HomepageDifferentiation: require('/opt/build/repo/components/Homepage/Differentiation.vue').default,HomepageTopContentNew: require('/opt/build/repo/components/Homepage/TopContentNew.vue').default,CoursesWhy: require('/opt/build/repo/components/Courses/Why.vue').default})
